import React from 'react';

class Information extends React.Component {
    render(){
        return (
            <div id="page-content">
                <h2 className="info-title">
                    Information
                </h2>
                <div id="info-content">
                    As it says on the home page, this is not a full time business.
                    <br /><br />
                    Some items may have imperfections - Imperfections arise from transporting goods to and from craft stalls, or are a part of upcycling products that are too good to throw out but may have minor scratches.  Where this is the case it is stated clearly on the product page and by purchasing that item you acknowledge and accept that. 
                    <br /><br />
                    When items are in stock, they will be dispatched within 2 days. When items are made to order, there will be a 1-2 week delivery time.  All items will be sent via Royal Mail.
                    <br /><br />
                    Payments are managed through Stripe. You do not need a Stripe account to be able to order.
                    <br /><br />
                    If you have any problems at all, please email me at <span className="email-hello"><a href="mailto:hello@gifted-cards.co.uk">hello@gifted-cards.co.uk</a></span> and I will reply as soon as I possibly can.
                </div>
            </div>  
        )
    }
}

export default Information;
