import React from 'react';
import $ from 'jquery';

class Blog extends React.Component {
    componentDidMount(){
        $.getJSON('https://www.googleapis.com/blogger/v3/blogs/7925636016699045286/posts', {
            key: 'AIzaSyBgdfTfYlkCdjzLr58K2s3jSrpNgMRTlnM',
            limit: 5
        }).then(function (json) {
            //do something
            //console.log(json.items);

            $.each(json.items, function (i, item) {

                var date = item.published;
                var jsDate = new Date(date);
                var outDate = jsDate.toDateString();
                var text = item.content;
                var extract = text.substring(0, 150);
                
                $("#blog-left").append("<div class='blog-item'><div class='blog-title'><a href=" + item.url + " target='_blank'>" + item.title + "</a></div><br /><div class='blog-date'>" + outDate + "</div><br /><div class='blog-excerpt'>" + extract + " ...<a href=" + item.url + " target='_blank'>more</a></div></div>");

                if (i == 0)
                {
                    $("#latest-post").append("<a href=" +item.url + " target='_blank'>" + item.title + "</a>, " + outDate + "</br>");
                }

                if (i > 0 && i < 6)
                {
                    $("#top-five").append("<a href=" + item.url + " target='_blank'>" + item.title + "</a>, " + outDate + "</br>");
                }
            });

            });
    }

    render(){
        return (
            <div id="page-content">
                <div id="page-wrapper" className="blog-wrapper">
                    <div id="blog-content">
                        <div id="blog-title"><h1>The Gifted Cards Blog</h1></div>
                        <div id="blog-description">The full blog is hosted on Blogger, this page contains an overview of posts. If you would like to read any of the posts in full, please click any of the "more" links or titles and you will be taken straight to the relevant post.  <br />Go to <a href="https://keepingmeamused.blogspot.com/" target="_blank">Keeping Me Amused</a> to see all posts.</div>
                        <div id="blog-left" className="blog-left"> 
                        
                        </div>
                        <div id="blog-mid"></div>
                        <div id="blog-right">
                            <div id="blog-filter">
                                <div id="latest-post">
                                    <div id="Label4" runat="server"><h3>Latest Post</h3></div>
                                </div>
                                <br />
                                <div id="top-five">
                                    <div id="Label5" runat="server"><h3>Previous 5 Posts</h3></div>
                                </div>
                                <br />
                                <br />
                                
                            </div>
                        </div>
                    </div>
                </div>    
            </div>  
        )
    }
}

export default Blog;
