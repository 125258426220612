import React from 'react';
import {Routes, Route} from 'react-router-dom';
import './styles/gcstyles-bw.css';
import './styles/gcstyles.css';
import Header from './constants/header.js';
import Footer from './constants/footer.js';
import Home from './pages/home';
import Information from './pages/information';
import Blog from './pages/blog';
import Privacy from './pages/privacy';

function App() {
  return (
    <div className="App" id="main">
      <Header />
      <main>
        <div id="site-content">
          <div id="floating-menu">
              <ul>
                  <li id="btnPrint"><i className="fas fa-print"></i></li>
                  <li id="btnAdjust"><i className="fas fa-adjust"></i></li>
                  <li><a href="https://www.facebook.com/giftedcards"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href="https://twitter.com/giftedcards"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="mailto:hello@gifted-cards.co.uk"><i className="far fa-envelope"></i></a></li>
              </ul>
          </div>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/information" element={<Information />} />
          <Route path="/about" element={<Information />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/blog" element={<Blog />} />
          <Route path='/shop' component={() => { 
              window.location.href = 'https://www.numonday.com/shop/giftedcards/'; 
              return null;
          }}/>
        </Routes>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
