import React from 'react';
import {Link} from 'react-router-dom';

class Home extends React.Component {
    render(){
        return (
            <div id="page-content">
                <div id="page-title">
                    Welcome to Gifted Cards
                </div>
                <div id="main-content">
                    <p>Gifted Cards is very much a hobby site, not a full time business, but if you are looking for something unusual and hand made, then you're in the right place!</p>
                    <br /><br />
                    <p>I love making things!! However, I stopped making things for a long time because if I make things without a purpose my home gets full of pretty things sitting around doing nothing. So, I decided to set this site up to sell bits and pieces, so that I can enjoy making things in my free time and (hopefully) give them a purpose by selling them.</p>
                    <br /><br />
                    <p>I have taken the shop off this site and am now using Numonday, click the Shop button below to take a look.</p>
                    <br /><br />
                    <p>If you're a crafty person and want to read about how some of these items, and more, have been created, take a look at my blog. I haven't posted recently but I'll get some more info up there soon. Get in touch if there is any process you would like me to share.</p>
                    <br /><br />
                    <div id="home-nav">
                        <div>&gt;<i className="fas fa-shopping-basket"></i><a href="https://www.numonday.com/shop/giftedcards/">Shop</a></div>
                        <div>&gt;<i className="fab fa-blogger-b"></i><Link to="/blog">Blog</Link></div>
                    </div>
                </div>
            </div>   
        )
    }
}

export default Home;
