import React, { Component, useEffect } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import sitelogo from '../images/gc-logo.png';
import Styles from '../styles/gcstyles.css'
import StylesBW from '../styles/gcstyles-bw.css'

class Header extends Component {
    componentDidMount(){
        $("#Styles").attr('href', {Styles});

        var btnP = document.getElementById("btnPrint");
        btnP.style.cursor = "pointer";
        btnP.onclick = function () { window.print(); };

        var btnA = document.getElementById("btnAdjust");
        btnA.style.cursor = "pointer";
        btnA.onclick = function () {
            var stylesheet = $("#Styles").attr('href'); 

            if (stylesheet == {Styles})
            {
                $("#Styles").attr('href', {StylesBW});
            }
            else
            {
                $("#Styles").attr('href', {Styles});
            }
        };
    }

    render() {
      return (
        <header>
            <div id="site-header">
                <div id="site-logo">
                    <img src={sitelogo} />
                </div>
                <div id="site-cover">
                    <div className="glow">Gifted Cards</div>
                </div>
                <div id="site-menu">
                    <ul className="nav">
                        <li className="menu-button"><div className="li-inner"><div className="li-mid"><Link to="/">Home</Link></div></div></li>
                        <li className="menu-button"><div className="li-inner"><div className="li-mid"><a href="https://www.numonday.com/shop/giftedcards/">Shop</a></div></div></li>
                        <li className="menu-button"><div className="li-inner"><div className="li-mid"><Link to="/blog">Blog</Link></div></div></li>
                    </ul>
                </div>
            </div>
            
        </header>
      );
    }
  }
   
export default Header;