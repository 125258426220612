import React from 'react';

class Privacy extends React.Component {
    render(){
        return (
            <div id="page-wrapper" className="blog-wrapper">
                <div id="page-content">
                    <h2 className="privacy-title">Privacy Policy</h2>
                    <div id="privacy-policy">
                        This site does not collect any personal information.  However, some elements on this site may place a cookie in your browser.
                        <br /><br />
                        The icons are provided by FontAwesome and their privacy policy can be found here: <a href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a>
                        <br /><br />
                        Blog posts are provided through Blogger and their privacy policy can be found here: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                        <br /><br /><br /><br />
                        When you place an order on the site, I receive an email containing your personal information which enables me to send your goods to you. That information will only be kept as long as is required to process your order and comply with statutory rights regarding returns.
                        <br /><br />        
                    </div>
                </div>
            </div>   
        )
    }
}

export default Privacy;
